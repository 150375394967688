<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">{{ day }} 
                        <a :href="edit_link">Edit</a>
                    </div>

                    <div class="card-body">
                        <ul>
                            <li v-for="hour in hours">  {{ hour.start_time }} </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['day','hours','edit_link'],
        data(){
            return {

            }
        },
        mounted() {
            console.log('TEacher work Day component mounted.')
        }
    }
</script>
