<template>

    <div 
    class="tab-pane fade"
    :class="{ 'show': isActive, 'active': isActive }"
    :id="contentId"
    role="tabpanel" 
    :aria-labelledby="tabId"
    >
    <slot></slot>
    </div>

</template>

<script>
    export default {

        props: {
            name: { required: true },
            id: { required: true },
            active: { default: false },
            create: { default: false },
        },

        data(){

        return { 
            isActive: false ,
            nameStub : this.name.toLowerCase().replace(/ /g, '-') + '-' + this.id,


        }
        },

        computed: {

            tabId() { 

                return  this.create ? 'tab-create' : 'tab-' + this.nameStub;
            },

            contentId() { 
                return 'content-' + this.nameStub;
            },

        },

        mounted() {
            this.isActive = this.active;
        },

    }
</script>
