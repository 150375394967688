<template>
<div>
<form method="POST" @submit.prevent="" action="#" class="ja-form">

<div class="row form-group">
    <div class="col-sm-4 pb-2 pb-sm-0"> 
        <label :for="$id('class_name')" class="col col-sm-auto col-form-label">
            Class Name</label>
    </div>
    <div class="col-sm pb-2 pb-sm-0">

        <input id="$id('class_name')" type="text" class="form-control" 
        v-model="class_name" :class="{'is-invalid': allerrors.class_name}">

        <div v-if="allerrors.class_name" class="text-danger">
            @{{ allerrors.class_name[0] }}
        </div>

    </div>

</div> <!-- /row -->
<button type="button" class="btn btn-secondary" 
v-on:click="$emit('close-create-class')"">
    Cancel
</button>
<button type="submit" class="btn btn-primary" v-on:click="submitStep0()">
    Submit
</button>


</form>
</div>
</template>

<script>
    export default {

        props: {
            student: { required: true },
            url: { required: true },
        },

        data(){

            return { 

                allerrors : {},
                class_name : '',

            };
        },

        created() {
            
        },

        computed: {

        },

        methods: {
            submitStep0() {
              
            },
        }

    }
</script>
