<template>
<modal-bootstrap :id="id">


<form method="POST" @submit.prevent="" action="#" class="ja-form">

<div class="modal-header">
    <h5 class="modal-title">
        Class Length: {{ classTime }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
<div class="modal-body" :id="id + '-modal-body'">
<div class="container-fluid">


<!-- ******************** ******************** -->


<div class="modal-wrapper" >

<transition name="fade" 
mode="out-in"
@beforeLeave="beforeLeave" 
@enter="enter" 
@afterEnter="afterEnter" 
>


<!-- SHOW WEEK OVERVIEW -->
<div v-if="step == 0" key="0">

     <div class="row">

        <div class="col-6 col-md-4 col-lg-3"
         v-for="(time_blocks, index) in DAYS_TBS_WBS"
        >
            <a href="#" v-on:click="submitStep0(index)">
            <div class="card card-nav-tabs card-day-schedule">
                <div class="card-header card-header-danger">
                    {{ index }}  
                </div>
                <ul class="list-group list-group-flush">

                    <li class="list-group-item">
                         <div class="row no-gutters align-items-center">
                             <div class="col-6 d-flex justify-content-center">
                                <img class="img " src="/-images/student/icon-morning.png">
                            </div>
                            <div class="col-6 d-flex justify-content-center">
                                {{ Object.keys(time_blocks.Morning).length }}
                            </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                        <div class="row no-gutters align-items-center">
                             <div class="col-6 d-flex justify-content-center">
                                <img class="img " src="/-images/student/icon-afternoon.png">
                            </div>
                            <div class="col-6 d-flex justify-content-center">
                                {{ Object.keys(time_blocks.Afternoon).length }}
                            </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                        <div class="row no-gutters align-items-center">
                             <div class="col-6 d-flex justify-content-center">
                                <img class="img " src="/-images/student/icon-evening.png">
                            </div>
                            <div class="col-6 d-flex justify-content-center">
                                {{ Object.keys(time_blocks.Evening).length }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div> <!-- /card -->
        </a>

        </div> <!-- /col -->


    </div> <!-- /row -->


</div> <!-- /if -->


<!-- SHOW DAY (Morning Afternoon Evening ) -->
<div v-else-if="step == 1"  key="1">

    <div class="form-group row">
        <div class="col">
            <button type="submit" class="btn btn-sm btn-secondary" v-on:click="step = 0;">
                < Back
            </button>
        </div>
    </div>  

    <div class="row">


        <!-- for each time_block -->
        <div class="col-12 col-md-4" v-for="(tb) in this.time_blocks">

            <div class="card card-nav-tabs card-day-schedule">
                <div class="card-header card-header-danger">
                   {{ tb }}
                </div>

                <template v-if="Object.keys(DAYS_TBS_WBS[currentDay][tb]).length > 0">
                <ul class="list-group list-group-flush">

                     <!-- for each work_block -->
                    <li class="list-group-item" 
                    v-for="(wb, index) in DAYS_TBS_WBS[currentDay][tb]"
                    >
                        <a href="#" v-on:click="submitStep1(DAYS_TBS_WBS[currentDay][tb][index])">
                        {{ wb.start_time }} - {{ wb.end_time }}</a></BR>
                        {{ wb.teacher.firstname }}</BR>
                        Index: {{ index }}
                        </a>
                
                    </li>
                </ul>
                </template>

                <template v-else>
                    No classes available
                </template>


            </div> <!-- /card -->

        </div> <!-- /col -->




    </div> <!-- /row -->


</div>  <!-- if -->


<!-- SHOW LESSON -->
<div v-else-if="step == 2"  key="2">


    <div class="row align-items-center">

        <div class="col">

            <div class="card card-nav-tabs card-day-schedule">
                <div class="card-header card-header-danger">
                    {{ this.currentWorkblock.start_time }}
                    {{ this.currentWorkblock.end_time }}

                    Step 3
                </div>
            </div> <!-- /card -->

        </div> <!-- /col -->

    </div> <!-- /row -->

    <div class="row form-group align-items-center">
    <div class="col-sm-4 pb-2 pb-sm-0">
        First Lesson
    </div>
    <div class="col-sm pb-2 pb-sm-0">

        <select :id="$id('month')" v-model="newClassDate" class="form-control">

        <template v-for="(date, index) in DAYS_DATES[currentDay]">


            <option :value="date['data']" :selected="index === 0" :disabled="date['holiday']" >
                {{ date['text'] }} <template v-if="date['holiday']"> Holiday </template>
            </option>

        </template>
        </select>

    </div>

    </div>

    <button type="button" class="btn btn-secondary"  v-on:click="step = 1;">Cancel</button>
    <button v-on:click="submitConfirm()" type="button" class="btn btn-primary">Confirm</button>

</div>  <!-- if -->


</transition>

</div> <!-- modal wrapper -->

</div> <!-- container fluid-->
</div> <!-- modal body-->
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    <!-- <button v-on:click="submit" type="button" class="btn btn-primary">Save changes</button> -->
</div>

</form>



</modal-bootstrap>
</template>

<script>
    export default {

        props: {
            id: { required: true },
            student: { required: true }, //object
            studentclass: { type: Object, required: true }, //object
        },

        data(){

            return {

                step: 0,
                url : 'student.class.api.schedule',
                urlTest : 'student.class.schedule-test',

                // How many work blocks length the class is. Set in initialize from server
                classLength: 1,


                // How long the call is in minutes. Set in initialize
                classTime: Config.workBlockLength,

               
                //workBlockLength: Config.workBlockLength,
                //oneWorkBlockBreak: Config.oneWorkBlockBreak,
                //manyWorkBlocksBreak: Config.manyWorkBlocksBreak,

                //time_blocks used for looping
                time_blocks : ['Morning','Afternoon','Evening'],

                //the number of  available lessons in the morning / afternoon / evening for each day of the week
                // set when opening the modal with the initialize method
                DAYS_TBS_WBS : [],

                //AVAILABLE CLASS STARTING DATES FOR EACH DAY
                // month-day no leading zero
                // DAYS_DATES[$day][array][1-7]
                DAYS_DATES : [],

                currentDay : '',
                
                // sent to server in confirm step
                currentWorkblock : '',

                
                allerrors : {},

                currentclass : {},
                
                success : false,

                classSchedule: '',

                // Final Confirm step - first class date
                newClassDate : '',

                prevHeight: 0

            }
        },
        computed: {
            // daysInMonth : function() { 
            // // Use 1 for January, 2 for February, etc. use:  daysInMonth(2, 1999)
            //     let numOfDays = new Date(this.year, this.month, 0).getDate();

            //     let days = [];
            //     for (var i = 1; i <= numOfDays; i++) {
            //        days.push(i.toString()); // must be a string because all v-modals are strings
            //     }

            //     return days;
            // },
        },

        watch: {
            currentDay: function (newValue, oldValue) {
                    // set the dropdown to the first available date of the day
                  this.newClassDate = this.DAYS_DATES[newValue][0]['data']
                },

        },

        methods: {

           
              submitStep0 : function (day) { 

                this.step = 1;

                this.currentDay = day;

                console.log(day + 'selected');




            }, // submit 

            submitStep1 : function (currentWorkblock) { 

                this.step = 2;
                this.currentWorkblock = currentWorkblock; // Object Passed

                console.log('submit step 1');

            }, // submit 

            submitConfirm : function (event) { 

                console.log('submit step 2');


                let dataform = new FormData();

                //dataform.append('_method', 'PATCH');   
                // VERY IMPORTANT FOR PATCH axios.patch method doesn't work

                // Not sure how many steps there will be. Final step is confirm
                dataform.append('step', 'confirm');

                dataform.append('workBlockId', this.currentWorkblock.id);
                dataform.append('classLength', this.classLength);
                dataform.append('newClassDate', this.newClassDate);
                
                if ( this.currentWorkblock.hasOwnProperty('connectedWbIds') ) {
                    dataform.append('connectedWbIds', JSON.stringify(this.currentWorkblock.connectedWbIds));
                }
                
                let url = route(this.url,{ student: this.student.id, class: this.studentclass.id});

                console.log('confirm url:' + url);

                axios.post(url, dataform)
                .then( response => {

                    if (response.data.success) {
                    
                        //reload students
                        this.$emit('saved');

                        $("#" + this.id).modal('hide');

                        this.allerrors = {};
                        this.step = 0;
                        //$(document).scrollTop(0);


                    } else {
                        console.log('Incorrect Data Returned:');
                        console.log(response.data);

                    }

                } ).catch((error) => {

                    console.log('error below :');
                    console.log(error.response.data);

                    this.allerrors = error.response.data.errors;
                    this.success = false;
                });

            }, // submit 

            initialize() {

                
                if (this.studentclass.id) {
            
                    console.log("INITIALIZED: Schedule Join Class Modal. ID: " + this.uid);

                    let printobject = JSON.parse(JSON.stringify(this.studentclass));
                    console.log(printobject);


                    let dataform = new FormData();

                    // Which step are we? 
                    dataform.append('step', this.step);

                    //console.log(this.student);
                    //console.log(this.studentClass);

                    //let url = this.makeUrl(this.url, this.student.id, this.currentClass.id);

                    let url = route(this.url,{ student: this.student.id, class: this.studentclass.id});
                   
                    axios.post(url, dataform)
                    .then( response => {

                        if (response.data.success) {

                            this.DAYS_TBS_WBS = JSON.parse(response.data.DAYS_TBS_WBS);
                            this.DAYS_DATES = JSON.parse(response.data.DAYS_DATES);

                            this.classLength = JSON.parse(response.data.classLength); // in workblocks
                            this.classTime = JSON.parse(response.data.classTime); // in minutes

                            let printobject = JSON.parse(JSON.stringify( this.DAYS_DATES));
                            console.log(printobject);



                        } else {
                            console.log('Schedule Initialize: Incorrect Data Returned:');
                            console.log(response.data);

                        }

                    } ).catch((error) => {

                        console.log('error below');
                        console.log(error.response.data);

                    });

                } // end if studentClass 
            },

            beforeLeave(element) {
                // $( '#id' ).css("height") returns value with px
                // $( '#id' ).height() returns number only
                // parseInt('245px', 10); removes letters from the end of numbers and returns integer but needs base 10


                let targetelement = document.getElementById(this.id + '-modal-body');
                console.log('JS Height:' + getComputedStyle(targetelement).height);;


                this.prevHeight = $( '#' + this.id + '-modal-body' ).css("height");

                console.log( 'Jquery height' + $( '#' + this.id + '-modal-body' ).css("height") );
                //console.log('element:' + targetelement);
            },



            enter(element) {

                let targetelement = document.getElementById(this.id + '-modal-body');
                
                // let height  = getComputedStyle(targetelement).height;

                let height = $( '#' + this.id + '-modal-body' ).css("height");

                
                // let height = $( '#' + this.id + '-modal-body' ).css("height");

                // if ( parseInt(height,10) > parseInt(this.prevHeight,10) ) {

                //     targetelement.style.height = height;
                // }


                // Sometimes height is BIGGER than view port Add check to see if bigger than view port.
                // and change to smaller

                console.log('weird hight:' + height);

                console.log('previous height' + this.prevHeight);


                // if (parseInt(this.prevHeight, 10) > parseInt(height, 10)) {

                    console.log('pass height test' + this.prevHeight + '>' + height)

                    //console.log(targetelement);

                    targetelement.style.height = this.prevHeight;

                    setTimeout(() => {
                    targetelement.style.height = height;
                    });

                // }



            // setTimeout(() => {
            //     targetelement.style.height = height;
            //   }, 3000);


            //done();
            },

            afterEnter(element) {
                let targetelement = document.getElementById(this.id + '-modal-body');

                targetelement.style.height = 'auto';
                console.log('after enter done');
            },


          

            // submitStep1 : function (event) { 

            //     let dataform = new FormData();

            //     dataform.append('_method', 'PATCH');   
            //     // VERY IMPORTANT FOR PATCH axios.patch method doesn't work

            //     // Which step are we? 
            //     dataform.append('step', this.step);

            //     dataform.append('share_code', this.share_code);


            //     axios.post(this.url, dataform)
            //     .then( response => {

            //         if (response.data.success) {
            //             this.studentclass = response.data.studentclass;

            //             //reload students
            //             this.$emit('saved');

            //             this.allerrors = {};
            //             this.step = 2;
            //             $(document).scrollTop(0);


            //         } else {
            //             console.log('Incorrect Data Returned:');
            //             console.log(response.data);

            //         }

            //     } ).catch((error) => {

            //         console.log('error below 1');
            //         console.log(error.response.data);

            //         this.allerrors = error.response.data.errors;
            //         this.success = false;
            //     });


            // }, //. submit

        }, // methods

        mounted() {
            
            console.log("MOUNTED: Schedule Join Class Modal. ID: " + this.uid);

 
        }

    }
</script>
