<template>
<modal-bootstrap :id="id">


<form method="POST" @submit.prevent="" action="#" class="ja-form">

<div class="modal-header">
    <h5 class="modal-title">
        Title
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
<div class="modal-body" :id="id + '-modal-body'">
<div class="container-fluid">


<!-- ******************** ******************** 

  <div class="col-6 col-md-4 col-lg-3"
     v-for="(time_blocks, index) in DAYS_TBS_WBS"
    >

-->

<table class="table">
  <thead class="bg-primary text-white">
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Lesson</th>
      <th scope="col">Homework</th>
      <th scope="col">Absent students</th>
    </tr>
  </thead>
<tbody>
  <tr v-for="(lr, index) in lessonRecords">
    <td>{{ lr.lesson_date }}</td>
    <td>{{ lr.lesson_content }}</BR>
            <template v-for="(lb, index) in lr.lessonBooks">
                {{ lb.title }} : {{ lb.start_page }} - {{ lb.end_page }} {{ lb.comment }}</BR>
            </template>
    </td>
    <td>{{ lr.lesson_homework }}</BR>
            <template v-for="(lb, index) in lr.homeworkBooks">
                {{ lb.title }} : {{ lb.start_page }} - {{ lb.end_page }} {{ lb.comment }}</BR>
            </template>

    </td>
    <td><template v-for="(st, index) in lr.absent">
            <span v-if="index !== 0">, </span>{{ st.studentname }}
        </template></td>
  </tr>
 
</tbody>
</table>


</div> <!-- container fluid-->
</div> <!-- modal body-->
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ __("Close") }}</button>
    <!-- <button v-on:click="submit" type="button" class="btn btn-primary">Save changes</button> -->
</div>

</form>


</modal-bootstrap>
</template>

<script>
    export default {

        props: {
            id: { required: true },
            student: { required: true }, //object
            studentclass: { type: Object, required: true }, //object
        },

        data(){

            return {

                url : 'student.class.api.lesson-records',

                //
                lessonRecords : [],

                allerrors : {},

                prevHeight: 0

            }
        },
        computed: {
            // daysInMonth : function() { 
            // // Use 1 for January, 2 for February, etc. use:  daysInMonth(2, 1999)
            //     let numOfDays = new Date(this.year, this.month, 0).getDate();

            //     let days = [];
            //     for (var i = 1; i <= numOfDays; i++) {
            //        days.push(i.toString()); // must be a string because all v-modals are strings
            //     }

            //     return days;
            // },
        },

        watch: {
            // currentDay: function (newValue, oldValue) {
            //         // set the dropdown to the first available date of the day
            //       this.newClassDate = this.DAYS_DATES[newValue][0]['data']
            //     },

        },

        methods: {

           
            initialize() {

                
                if (this.studentclass.id) {
            
                    console.log("INITIALIZED: Lesson Records Modal. ID: " + this.uid);

                    //let printobject = JSON.parse(JSON.stringify(this.studentclass));
                    //console.log(printobject);

                    let dataform = new FormData();

                    //console.log(this.student);
                    //console.log(this.studentClass);

                    //let url = this.makeUrl(this.url, this.student.id, this.currentClass.id);

                    let url = route(this.url,{ student: this.student.id, class: this.studentclass.id});
                   
                    axios.post(url, dataform)
                    .then( response => {

                        if (response.data.success) {

                            this.lessonRecords = response.data.lessonRecords;
                            // let printobject = JSON.parse(JSON.stringify( this.DAYS_DATES));
                            // console.log(printobject);

                        } else {
                            console.log('Lesson Records Modal Initialize: Incorrect Data Returned:');
                            console.log(response.data);

                        }

                    } ).catch((error) => {

                        console.log('error below');
                        console.log(error.response.data);

                    });

                } // end if studentClass 
            },



        }, // methods

        mounted() {
            
            console.log("MOUNTED: Lesson Records Modal. ID: " + this.uid);

 
        }

    }
</script>
