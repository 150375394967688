/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;


// MAKES COONFIG VARIABLES AVAILABLE TO JAVASCRIPT
// Variables are uilled from PHP in 
// views/layouts/header-config-script.blade.php 
window.Config = {};

// THIS SYNTAX DOESN'T WORK!!! WHY? ??????
//window.UniqueId = require('vue-unique-id');
// THis DOES WORK!!!!


import UniqueId from 'vue-unique-id';
Vue.use(UniqueId);

// exports correcltly but cant use 
//const UniqueId = () => import(/* webpackChunkName: "uniqueid" */'vue-unique-id');



Vue.directive('tooltip', {
  bind: function bsTooltipCreate(el, binding) {
    let trigger;
    if (binding.modifiers.focus || binding.modifiers.hover || binding.modifiers.click) {
      const t = [];
      if (binding.modifiers.focus) t.push('focus');
      if (binding.modifiers.hover) t.push('hover');
      if (binding.modifiers.click) t.push('click');
      trigger = t.join(' ');
    }
    $(el).tooltip({
      title: binding.value,
      placement: binding.arg,
      trigger: trigger,
    });
  }
});

Vue.mixin(require('./trans'));


//DOESNT WORK
// const AsyncComponent = () => import( webpackChunkName: "AsyncComponent" './components/AsyncComponent.vue');

//WORKS!
Vue.component('AsyncComponent', function (resolve) {
  require(['./components/AsyncComponent.vue'], resolve)
})


////////
Vue.component("year-calendar",require("./components/JqueryBootstrapYearCalendar.vue").default)

/////// Doesnt work but ONLY for the calendar
// Maybe because of the jquery reuire inside.
// Vue.component('JqueryBootstrapYearCalendar', function (resolve) {
//   require(['./components/JqueryBootstrapYearCalendar.vue'], resolve)
// })


Vue.component("crop-upload",require("./components/CropUploadComponent.vue").default)



Vue.component('teacher-work-day', require('./components/TeacherWorkDay.vue').default);
Vue.component('teacher-edit-day', require('./components/TeacherEditDay.vue').default);
Vue.component('day-num-lessons', require('./components/DayNumLessons.vue').default);
Vue.component('modal', require('./components/ModalTemplate.vue').default);
Vue.component('modal-bootstrap', require('./components/ModalTemplateBootstrap.vue').default);
Vue.component('edit-student-modal', require('./components/EditStudentModal.vue').default);

Vue.component('student-join-class-modal', require('./components/StudentJoinClassModal.vue').default);
Vue.component('student-create-class', require('./components/StudentCreateClass.vue').default);
Vue.component('student-schedule-modal', require('./components/StudentScheduleModal.vue').default);
Vue.component('student-lesson-records-modal', require('./components/StudentLessonRecordsModal.vue').default);

Vue.component('test-schedule', require('./components/TestSchedule.vue').default);



Vue.component('tab-group', require('./components/TabGroup.vue').default);
Vue.component('tab-child', require('./components/TabChild.vue').default);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
