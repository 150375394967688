<template>
<modal-bootstrap :id="id">

<form method="POST" @submit.prevent="" action="#" class="ja-form">

<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
<div class="modal-body">
<div class="container-fluid">

<!-- ******************** STUDENT FIRST NAME KANJI ******************** -->

<div class="row form-group align-items-center">
    <div class="col-sm-4 pb-2 pb-sm-0"> 
        Name in Kanji
    </div>

    <div class="col-sm">

        <div class="form-row">

            <label :for="$id('studentname')" class="col-2 col-sm-auto col-form-label">First Name</label>

            <div class="col-10 col-sm">

                <input :id="$id('studentname')" type="text" class="form-control" v-model="form.studentname" :class="{'is-invalid': allerrors.studentname}">

                <div v-if="allerrors.studentname" class="text-danger">
                    {{ allerrors.studentname[0] }}
                </div>
            </div>

        </div>


    </div>
</div> <!-- /row -->


<!-- ******************** STUDENT FIRST NAME KANA ******************** -->

<div class="row form-group align-items-center">
    <div class="col-sm-4 pb-2 pb-sm-0">
        Name in Furigana
    </div>

    <div class="col-sm">

        <div class="form-row">

            <label :for="$id('studentname_furigana')" class="col-2 col-sm-auto col-form-label">First Name Furigana</label>

            <div class="col-10 col-sm">

                <input :id="$id('studentname_furigana')" type="text" class="form-control" v-model="form.studentname_furigana" :class="{'is-invalid': allerrors.studentname_furigana}">

                <div v-if="allerrors.studentname_furigana" class="text-danger">
                    {{ allerrors.studentname_furigana[0] }}
                </div>

            </div>

        </div>


    </div>
</div> <!-- /row -->

<!-- ******************** STUDENT GENDER ******************** -->

<div class="row form-group align-items-center">
    <div class="col-sm-4 pb-2 pb-sm-0">
       Gender
    </div>
    <div class="col-sm pb-2 pb-sm-0">
        <div class="form-row">    
            <div class="custom-control custom-radio">
                <input
                  type="radio"
                  :id="$id('male')"
                  v-model="form.student_gender"
                  class="custom-control-input"
                  value="male"
                />
                <label class="custom-control-label pr-4" :for="$id('male')">
                    Male
                </label>
            </div>
            <div class="custom-control custom-radio">
                <input 
                type="radio" 
                :id="$id('female')"
                v-model="form.student_gender" 
                class="custom-control-input" 
                value="female">
                <label class="custom-control-label pr-4" :for="$id('female')"
                  >Female
                </label>
            </div>
        </div>      <!-- /form row -->

        <div v-if="allerrors.student_gender" class="text-danger">
            {{ allerrors.student_gender[0] }}
        </div>

    </div> <!-- /input column -->

</div> <!-- /row form-group -->


<!-- ******************** DATE OF BIRTH  ******************** -->


<div class="row form-group align-items-center">
    <div class="col-sm-4 pb-2 pb-sm-0">
        Date of Birth
    </div>
    <div class="col-sm pb-2 pb-sm-0">

        <div class="form-row align-items-center">
    
            <label :for="$id('year')" class="col col-sm-auto col-form-label">
            Year</label>

            <div class="col">


            <select :id="$id('year')" class="form-control" v-model="year">
            <option selected>Year</option>
             
            <option v-for="year in years" v-bind:value="year">{{ year }}</option>
    
            </select>

            </div>


            <label :for="$id('month')" class="col-2 col-sm-auto col-form-label">
            Month</label>

            <div class="col">

            <select :id="$id('month')" v-model="month" class="form-control">
            <option disabled selected>Month</option>
            
            <option v-for="month in months"  v-bind:value="month">{{ month }}</option>
            </select>

            </div>

            <label :for="$id('day')" class="col-2 col-sm-auto col-form-label">
            Day </label>

            <div class="col">
            <select :id="$id('day')" v-model="day" class="form-control">
            <option disabled selected>Month</option>
            <option v-for="item in daysInMonth" :key="item">{{ item }}</option>
            </select>
            </div>

        </div>

    </div>

</div> <!-- /row -->


<!-- ******************** GRADE  ******************** -->

<div class="form-group row">
    <label :for="$id('grade')" class="col-sm-4 pb-2 pb-sm-0 col-form-label">
            Grade
    </label>
    <div class="col-md-7 my-auto">

        <select :id="$id('grade')" class="form-control" v-model="form.grade">
        <option value="" disabled selected>Choose</option>
        <option value="1">1年</option>
        <option value="2">2年</option>
        <option value="3">3年</option>
        <option value="4">4年</option>
        <option value="5">5年</option>
        <option value="6">6年</option>
        </select>

        <div v-if="allerrors.grade" class="text-danger">
            {{ allerrors.grade[0] }}
        </div>

    </div> <!-- /col -->
</div> <!-- /form group row-->




<!-- ******************** YEARS STUDIED ENGLISH  ******************** -->

<div class="form-group row">
    <label :for="$id('years_studied_english')" class="col-sm-4 pb-2 pb-sm-0 col-form-label">
            Years Studied English
    </label>
    <div class="col-md-7 my-auto">

        <select :id="$id('years_studied_english')" class="form-control" v-model="form.years_studied_english">
        <option value="" disabled selected>Choose</option>
        <option value="0">していません</option>
        <option value="1">1年間</option>
        <option value="2">2年間</option>
        <option value="3">3年間</option>
        <option value="4">4年間</option>
        <option value="5">5年間以上</option>
        </select>

        <div v-if="allerrors.years_studied_english" class="text-danger">
            {{ allerrors.years_studied_english[0] }}
        </div>

    </div> <!-- /col -->
</div> <!-- /form group row-->



<!-- ******************** IMAGE ******************** -->

<div class="row form-group align-items-center">
    <label :for="$id('image')" class="col-sm-4 pb-2 pb-sm-0 col-form-label">
            Picture
    </label>

     <div class="col-sm-4" style="overflow: hidden;">
       <img class="img img-fluid img-raised rounded-circle" :src="'/storage/' + form.image">
    </div>

    <div class="col-sm-4" style="overflow: hidden;">
        <crop-upload ref="cropimage">
        </crop-upload>
    
    </div>
</div> <!-- /row -->


</div> <!-- container fluid-->
</div> <!-- modal body-->
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    <button v-on:click="submit" type="button" class="btn btn-primary">Save changes</button>
</div>

</form>

</modal-bootstrap>
</template>

<script>
    export default {

        props: {
            student: { required: false },
            id: { required: true },
            create: { type: Boolean, default: false  },
        },

        data(){

            return {
                allerrors : {},

                years : this.yearsInForm(1990),
                months : [1,2,3,4,5,6,7,8,9,10,11,12],

                // cleaned in studentDetails model
                year : this.create ? '' : this.student.dob_year,
                month : this.create ? '' : this.student.dob_month,
                day : this.create ? '' : this.student.dob_day,

                form: {

                    studentname : this.create ? '' : this.student.studentname,
                    studentname_furigana : this.create ? '' : this.student.studentname_furigana,
                    student_gender : this.create ? '' : this.student.student_gender,
                    years_studied_english : this.create ? '' : this.student.years_studied_english,
                    grade : this.create ? '' : this.student.grade,
                    image : this.create ? '' : this.student.image,

                },

                imageBlob : '',


            }
        },
        computed: {
            daysInMonth : function() { 
            // Use 1 for January, 2 for February, etc. use:  daysInMonth(2, 1999)
                let numOfDays = new Date(this.year, this.month, 0).getDate();

                let days = [];
                for (var i = 1; i <= numOfDays; i++) {
                   days.push(i.toString()); // must be a string because all v-modals are strings
                }

                return days;
            },
  



        },

        methods: {

            yearsInForm : function(startYear) {
                startYear = (typeof(startYear) == 'undefined') ? 1980 : startYear
                var currentYear = new Date().getFullYear();
                var years = []
                for(var i=startYear;i<=currentYear;i++){
                    years.push(i);
                } 
                return years;
            },

            getCropperImage : async function (cropper) {

                // takes reference to cropper object. To Blob is async have to use a promise.
                // don't really understand this but.. ok.

                return new Promise(function(resolve, reject) {
                    cropper.getCroppedCanvas().toBlob(function(blob) {
                      resolve(blob)
                    })
                })
                //this.cropperImage = this.$refs.cropimage.$refs.cropper.getCroppedCanvas().toDataURL();

                //return this.cropperImage;

            },

            submit: async function () {
            // async because it has to await for blob image in this.getCropperImage
       

                console.log('submit function');



                
                let month = this.month.length < 2 ? '0' + this.month : this.month;

                let day = this.day.length < 2 ? '0' + this.day : this.day;

                let student_dob = this.year + '-' + month + '-' + day


                let dataform = new FormData();


// *******************************  PROBLEM PROBLEM PROBLEM PROBLEM PROBLEM PROBLEM PROBLEM PROBLEM 
// ******************************* URL ENDS UP BEING UNDEFINDED

                let url = '';
                if (this.create) {
                    // This is a form to store a new student
                     url = this.student.store_url;
                } else {
                    // This is an update form
                    //VERY IMPORTANT FOR PATCH axios.patch method doesn't work
                    dataform.append('_method', 'PATCH');
                    url = this.student.update_url;


                }

                //console.log(url);

                dataform.append('studentname', this.form.studentname);

                dataform.append('studentname_furigana', this.form.studentname_furigana);

                dataform.append('student_gender', this.form.student_gender);

                dataform.append('student_dob', student_dob);

                dataform.append('grade', this.form.grade);

                dataform.append('years_studied_english', this.form.years_studied_english);

                  console.log(this.$refs.cropimage.imageSrc);



//  CHECK IF NEW IMAGE IS UPLOADED

                if (this.$refs.cropimage.imageSrc){

                    console.log('this.$refs.cropimage.imageSrc');

                    this.imageBlob = await this.getCropperImage(this.$refs.cropimage.$refs.cropper);

                    dataform.append('image', this.imageBlob);
                }

                console.log('url' + url);

                axios.post(url, dataform)
                .then( response => {

                    console.log(response.data.message);

                    console.log('patch done');

                    this.allerrors = {};

                    this.success = true;

                    $("#" + this.id).modal('hide');

                    this.$emit('saved');

                    // this.close(); NEED BOOTSRAP CLOSE

                }).catch((error) => {


                    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('errors')){

                        this.allerrors = error.response.data.errors;
            

                        } else {

                        console.log('Error Caught:');
                        console.log(error);

                    }

                    this.success = false;

                  
                });

              
            }
        },
        mounted() {
            
            console.log("This component's unique id is: " + this.uid);
 
        }
    }
</script>
