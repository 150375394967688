<template>
<div class="calendar"></div>
</template>

<script>

    export default {
        props: { 
            // ******* [ "css-class" -> ["yyy-mm-dd","yyy-mm-dd"], "css-class" -> ["yyy-mm-dd","yyy-mm-dd"]]
            addDates: { required: true },
        },

        methods: {

            loadData: function () {

                let addDates = this.addDates;

                for (const key in addDates) {
                    if (addDates.hasOwnProperty(key)) {

                        let datearray = addDates[key];

                        for(var i=0; i < datearray.length; i++){

                            let datesplit = datearray[i].split('-');
                            //console.log(datesplit[0] + datesplit[1]);


                            $(this.$el).calendar('addClass', datesplit[0], datesplit[1], datesplit[2],key);
                        }

                        //console.log(key + ' - ' + addDates[key]);
                    }
                }
            }
        },

        mounted() {

            // ************ import() DOESNT WORK!!! whats the difference?!?!
            require("../jquery.bootstrap.year.calendar.js");
            $(this.$el).calendar({
                minYear: 2022,
                maxYear: 2023
            });

            
            this.loadData();

            //jQuery(this.$el).calendar();

            // check jquery version
            //console.log($().jquery);

            // check jquery plugin exists
            //console.log($().calendar);

          
            //console.log(this.$el);



            // $(this.$el).calendar('addClass', 2022, 3, 4,'red');
            // $(this.$el).calendar('addClass', 2022, 3, 5,'red blue');
            //$(this.$el).calendar('removeAllClasses', 2022, 3, 5);



            let vueself = this;  // have to save this which refers to the vue app (maybe?)
            // This function starts a NEW scope so this will refer to function of something.

            // $calendar.find('.jqyc-prev-year').on("click", showPreviousYear,);
            $(vueself.$el).on('jqyc.changeYearToNext', function (event) {
    
                vueself.loadData();

                //console.log('caught next year event')

            });

             $(vueself.$el).on('jqyc.changeYearToPrevious', function (event) {
    
                vueself.loadData();

                console.log('caught previous year event')

            });


            $(vueself.$el).on('jqyc.dayChoose', function (event) {

                
                //console.log(this); // calendar div

                let choosenYear = $(vueself.$el).data('year');
                let choosenMonth = String( $(vueself.$el).data('month') ).padStart(2, '0');
                let choosenDay = String( $(vueself.$el).data('day-of-month') ).padStart(2, '0');

                let fulldate = choosenYear + '-' + choosenMonth + '-' + choosenDay;

                //console.log('emit ' + fulldate);

                vueself.$emit('choose-date', fulldate);
            });


            console.log('jquery bootstrap year calendar Component mounted.')



        }
    }


</script>
