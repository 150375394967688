<template>

<form method="POST" @submit.prevent="" action="#" class="ja-form">

<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
<div class="modal-body">
<div class="container-fluid">

<!-- ******************** ******************** -->

<template v-if="step === 0">

    <div class="row form-group align-items-center">
        <div class="col-sm-4 pb-2 pb-sm-0"> 
            Schedule Class</BR>
            {{ studentClass.id }}</BR>
            {{ student.studentname }}

        </div>
        <div class="col-sm pb-2 pb-sm-0">

            <div v-for="(time_blocks, index) in days_timeBlocks_numOfLessons">

                <ul>
                    <li v-for="(num, index) in time_blocks">  {{ index }} : {{ num }}  </li>
                </ul>   

            </div>

        </div>

    </div> <!-- /row -->

    <button type="submit" class="btn btn-primary" v-on:click="submitStep0()">
        Submit
    </button>

    </template>


    </div>
</div>
</form>

</template>




<script>
    export default {

        props: {
            id: { required: true },
            student: { required: true }, //object
            studentBanana: { type: Object, required: true }, //object
        },

        data(){

            return {

                step: 0,
                url : 'student.class.api.schedule',

                //the number of  available lessons in the morning / afternoon / evening for each day of the week
                days_timeBlocks_numOfLessons : '',

                
                allerrors : {},

                share_code : '',
                allerrors : {},

                currentclass : {},

                
                success : false,


                classSchedule: '',

            }
        },
        computed: {
            studentClass : function() { 
                return this.studentBanana;
            },

        },

        watch: {


        },

        methods: {

            initialize() {
            
                console.log("INITIALIZED: Schedule Join Class Modal. ID: " + this.uid);

                console.log(this.studentClass);

                let printobject = JSON.parse(JSON.stringify(this.studentClass));
                console.log(printobject);




                let dataform = new FormData();

                // Which step are we? 
                dataform.append('step', this.step);

                //console.log(this.student);
                //console.log(this.studentClass);

                //let url = this.makeUrl(this.url, this.student.id, this.currentClass.id);

                // let url = route(this.url,{ student: this.student.id, class: this.currentClass.id});

                // axios.post(url, dataform)
                // .then( response => {

                //     if (response.data.success) {

                //         this.days_timeBlocks_numOfLessons = response.data.days_timeBlocks_numOfLessons;


                //     } else {
                //         console.log('Incorrect Data Returned:');
                //         console.log(response.data);

                //     }

                // } ).catch((error) => {

                //     console.log('error below');
                //     console.log(error.response.data);

                // });

 
            },



            submitStep0 : function (event) { 

                let dataform = new FormData();

                dataform.append('_method', 'PATCH');   
    
                dataform.append('step', this.step);

                dataform.append('share_code', this.share_code);

                axios.post(this.url, dataform)
                
                .then( response => {
                    
                    // check for success == true to be safe
                    if (response.data.success) {

                        this.studentclass = response.data.studentclass;

                        this.step = 1;

                        console.log('Class:');
                        console.log(this.studentclass);


                        $(document).scrollTop(0);

                        this.allerrors = {};

                    } else {
                        console.log('Incorrect Data Returned:');
                        console.log(response.data);

                    }


                    // window.location.href = '{{ route('student.home') }}'; 

                } ).catch((error) => {



                    // Only set allerrors if there is validation data!
                    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('errors')){

                        this.allerrors = error.response.data.errors;
            

                        } else {

                        console.log('Error Caught:');
                        console.log(error);

                    }
                    

                });


            }, // submit 

            submitStep1 : function (event) { 

                let dataform = new FormData();

                dataform.append('_method', 'PATCH');   
                // VERY IMPORTANT FOR PATCH axios.patch method doesn't work

                // Which step are we? 
                dataform.append('step', this.step);

                dataform.append('share_code', this.share_code);


                axios.post(this.url, dataform)
                .then( response => {

                    if (response.data.success) {
                        this.studentclass = response.data.studentclass;

                        //reload students
                        this.$emit('saved');

                        this.allerrors = {};
                        this.step = 2;
                        $(document).scrollTop(0);


                    } else {
                        console.log('Incorrect Data Returned:');
                        console.log(response.data);

                    }

                } ).catch((error) => {

                    console.log('error below 1');
                    console.log(error.response.data);

                    this.allerrors = error.response.data.errors;
                    this.success = false;
                });


            }, //. submit

        }, // methods

        mounted() {
            
            console.log("MOUNTED: Schedule Join Class Modal. ID: " + this.uid);

 
        }

    }
</script>
