<template>
  
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">

        <div class="modal-content">
        <slot></slot>
        </div>


  </div>
</div>

</template>

<script>
    export default {
        props: ['id'],
          methods: {
            close: function () {
              this.$emit('close');
            }
          },
          mounted: function () {

            console.log('Model Template Id: ' + this.id);
           // document.addEventListener("keydown", (e) => {
            //  if (this.show && e.keyCode == 27) {
            //    this.close();
             // }
            //});
          }
    }
</script>
