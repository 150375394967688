<template>
<modal-bootstrap :id="id">

<form method="POST" @submit.prevent="" action="#" class="ja-form">

<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
<div class="modal-body">
<div class="container-fluid">

<!-- ******************** ******************** -->

<template v-if="step == 0">

    <div class="row form-group align-items-center">
        <div class="col-sm-4 pb-2 pb-sm-0"> 
            Share Code
        </div>
        <div class="col-sm pb-2 pb-sm-0">

            <input id="share_code" type="text" class="form-control" name="share_code" value="" v-model="share_code" :class="{'is-invalid': allerrors.share_code}">

            <div v-if="allerrors.share_code" class="text-danger">
                {{ allerrors.share_code[0] }}
            </div>

        </div>

    </div> <!-- /row -->

    <button type="submit" class="btn btn-primary" v-on:click="submitStep0()">
        Submit
    </button>

</template>

<template v-else-if="step == 1">

    <div class="row form-group align-items-center">
        <div class="col-sm-4 pb-2 pb-sm-0"> 
            
            Class {{ classSchedule }}

        </div>
        <div class="col-sm pb-2 pb-sm-0">

            <H3>Class Owner</H3> </BR></BR>

            Class Owner: {{ studentclass.student_owner.studentname }}({{ studentclass.student_owner.studentname_furigana }})

            <H3>Students</H3> </BR></BR>

            <template v-for="(student, index) in studentclass.students">
            {{ student.studentname }}({{ student.studentname_furigana }}) </BR>

            </template>


        </div>

    </div> <!-- /row -->

    <button type="submit" class="btn btn-primary" v-on:click="submitStep1()">
        Confirm
    </button>




</template>



<template v-else-if="step == 2">

    <div class="row form-group align-items-center">
        <div class="col"> 
            
            You have joined this class {{ classSchedule }}

        </div>
    </div>
    <div class="row form-group align-items-center">
        <div class="col"> 
            
            Class Schedule {{ classSchedule }}

        </div>
    </div>
    <div class="row form-group align-items-center">
        <div class="col"> 
            
            <H5>Class Owner</H5> 
            {{ studentclass.student_owner.studentname }}({{ studentclass.student_owner.studentname_furigana }})

        </div>
    </div>
    <div class="row form-group align-items-center">
        <div class="col"> 
            
            <H5>Student List</H5>

            <template v-for="(student, index) in studentclass.students">
            {{ student.studentname }}({{ student.studentname_furigana }}) </BR>

            </template>

        </div>
    </div>



</template>


</div> <!-- container fluid-->
</div> <!-- modal body-->
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    <!-- <button v-on:click="submit" type="button" class="btn btn-primary">Save changes</button> -->
</div>

</form>

</modal-bootstrap>
</template>

<script>
    export default {

        props: {
            student: { required: true },
            id: { required: true },
            url: { required: true },
        },

        data(){

            return {
                
                allerrors : {},

                share_code : '',
                allerrors : {},

                studentclass : {},

                success : false,

                step: 0,

                classSchedule: '',

            }
        },
        computed: {
            // daysInMonth : function() { 
            
            //     return days;
            // },

        },

        watch: {

            studentclass: function (newClass, oldClass) {
             
                if (newClass.work_block_id === null) {

                    this.classSchedule = "Unscheduled";
                }


            }

        },

        methods: {


            submitStep0 : function (event) { 

                let dataform = new FormData();

                dataform.append('_method', 'PATCH');   
    
                dataform.append('step', this.step);

                dataform.append('share_code', this.share_code);

                axios.post(this.url, dataform)
                
                .then( response => {
                    
                    // check for success == true to be safe
                    if (response.data.success) {

                        this.studentclass = response.data.studentclass;

                        this.step = 1;

                        console.log('Class:');
                        console.log(this.studentclass);


                        $(document).scrollTop(0);

                        this.allerrors = {};

                    } else {
                        console.log('Incorrect Data Returned:');
                        console.log(response.data);

                    }


                    // window.location.href = '{{ route('student.home') }}'; 

                } ).catch((error) => {



                    // Only set allerrors if there is validation data!
                    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('errors')){

                        this.allerrors = error.response.data.errors;
            

                        } else {

                        console.log('Error Caught:');
                        console.log(error);

                    }
                    

                });


            }, // submit 

            submitStep1 : function (event) { 

                let dataform = new FormData();

                dataform.append('_method', 'PATCH');   
                // VERY IMPORTANT FOR PATCH axios.patch method doesn't work

                // Which step are we? 
                dataform.append('step', this.step);

                dataform.append('share_code', this.share_code);


                axios.post(this.url, dataform)
                .then( response => {

                    if (response.data.success) {
                        this.studentclass = response.data.studentclass;

                        //reload students
                        this.$emit('saved');

                        this.allerrors = {};
                        this.step = 2;
                        $(document).scrollTop(0);


                    } else {
                        console.log('Incorrect Data Returned:');
                        console.log(response.data);

                    }

                } ).catch((error) => {

                    console.log('error below 1');
                    console.log(error.response.data);

                    this.allerrors = error.response.data.errors;

                    this.success = false;


                  
                });


            }, //. submit

        }, // methods

        mounted() {
            
            console.log("Student Join Class Modal: " + this.uid);
 
        }

    }
</script>
