<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">{{ day }} 
                    </div>

                    <div class="card-body">

                        <div class="row form-group align-items-center" v-for="(hour, index) in system_hours" :key="index">
                            <div class="col-sm-4 pb-2 pb-sm-0">
                                {{ hour }} : <div v-if="teacher_hours.includes(hour)">Available</div>
                            </div>
                            <div class="col-sm pb-2 pb-sm-0">
                                <div class="form-row">    

                                        <input class="form-check-label" type="checkbox" :id=hour  :value=hour v-model=checkboxes>
 
                                        <label class="form-check-label" for="live_in_japan">
                                          Work
                                        </label>

                                        <div v-if="checkboxes.includes(hour) && !teacher_hours.includes(hour)"> Not available -> available</div>

                                        <div v-if="!checkboxes.includes(hour) && teacher_hours.includes(hour)"> Available -> Not available</div>
                             
                                </div>   <!-- form-row -->


                            </div> <!-- col -->


                        </div> <!-- row  v-for -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['day','teacher_hours','system_hours'],
        data(){
            return {

                checkboxes : this.teacher_hours,

            }
        },
        mounted() {
            console.log('Teacher Edit Day component mounted.')
        }
    }
</script>
