<template>
<div>
    <div class="nav-wrapper">
    <ul class="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-icons-text" role="tablist">


    <template v-for="tab in tabs">    


    <template v-if="tab.create">  
        <li class="nav-item" >

        <a
        :class="{ 'active': tab.isActive }"
        class="nav-link mb-sm-3 mb-md-0"
        :id="tab.tabId"
        :href="'#' + tab.contentId"
        @click="showCreateModal()"
        role="tab" 
        :aria-controls="tab.href"
        aria-selected="true"
        >
            <i class="ni ni-cloud-upload-96 mr-2"></i>{{ tab.name }}
        </a>
        </li>


    </template>
    <template v-else> 

        <li class="nav-item" >

        <a
        :class="{ 'active': tab.isActive }"
        class="nav-link mb-sm-3 mb-md-0"
        :id="tab.tabId"
        :href="'#' + tab.contentId"
        @click="selectTab(tab.tabId)"
        role="tab" 
        :aria-controls="tab.href"
        aria-selected="true"
        >
            <i class="ni ni-cloud-upload-96 mr-2"></i>{{ tab.name }}
        </a>
        </li>

    </template>

    </template>

    </ul>
    </div> <!-- nav wrapper  -->
    <div class="card shadow">
        <div class="card-body">
            <div class="tab-content">
                <slot></slot>
            </div> <!-- tab content -->
        </div> <!-- card body -->
    </div> <!-- card shadow-->

</div>

</template>

<script>
    export default {

        data(){

            return { tabs: [] };
        },

        created() {
            this.tabs = this.$children;
        },

        computed: {

        },

        methods: {
            selectTab(tabId) {
                //console.log(tabId);
                $('#' + tabId).tab('show');
            },
            showCreateModal() {
                console.log('show create modal');
                this.$emit('click-student-create');
            }
        }

    }
</script>
