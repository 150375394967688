<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">{{ day }} 
                        <a :href="link">View</a>
                    </div>

                    <div class="card-body">
                        <ul>
                            <li v-for="(num, index) in time_blocks">  {{ index }} : {{ num }}  </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['day','time_blocks','link'],
        data(){
            return {

            }
        },
        mounted() {
            console.log('Day component mounted.')
        }
    }
</script>
