<template>
<div>
    <input type="file" name="image" accept="image/*" @change="setImage" />

    <div class="row ">
        <div class="col-6">

        <!-- Cropper container -->
          <vue-cropper
            class="mr-2"
            ref="cropper"
            :guides="true"
            :src="imageSrc"
            :aspect-ratio="1 / 1"
            :auto-crop-area="1"
          ></vue-cropper>

        </div>
    </div> <!-- row -->

</div> <!-- outside container -->
</template>

<script>
  import VueCropper from "vue-cropperjs"
  import "cropperjs/dist/cropper.css"

  export default {
    components: {
      VueCropper,
    },

    data: function () {
      return {
        imageSrc: "",
        croppedImageSrc: "",
      }
    },
    methods: {

      setImage: function (e) {
        const file = e.target.files[0]
        if (!file.type.includes("image/")) {
          alert("Please select an image file")
          return
        }
        if (typeof FileReader === "function") {
          const reader = new FileReader()
          reader.onload = event => {
            this.imageSrc = event.target.result

            // Rebuild cropperjs with the updated source
            //**** $refs.cropper is only first created when an image is uploaded

            if (this.$refs.cropper) {
                this.$refs.cropper.replace(event.target.result);
                console.log(this.$refs.cropper);
            }
          }
          reader.readAsDataURL(file)
        } else {
          alert("Sorry, FileReader API not supported")
        }
      },

       cropImage() {
        // Get image data for post processing, e.g. upload or setting image src
        this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.$refs.cropper.replace(this.croppedImageSrc);
      },


      uploadImage() {
        this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
          let formData = new FormData()

          // Add name for our image
          formData.append("name", "image-name-" + new Date().getTime())

          // Append image file
          formData.append("image", blob)

          axios
            .post("/student/testupload", formData)
            .then(response => {
              console.log(response.data)
            })
            .catch(function (error) {
              console.log(error)
            })
        })
      },

    },
  }
</script>